import React, { Component } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { Link } from 'react-router-dom';
import { autoPlay } from 'react-swipeable-views-utils';
import ViewIndicator from "./ViewIndicator"
import Menu from "./Menu"
import Hours from "./Hours"
import Contact from "./Contact"
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from "@material-ui/core/styles";

import feast from "../../images/feast.jpg"
import skewers from "../../images/skewers.jpg"
import pbf from "../../images/pbf.jpg"
import wings from "../../images/wings.jpg"
import pbn from "../../images/pbn.jpg"
import aceskey from "../../images/aceskey.jpg"
import './index.scss'
import Modal from '../Modal/ModalComp.js';


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const DineInDialog = withStyles({
  paper: {
    width: "360px",
    borderRadius: "26px",
    padding: "1em 2em",
    height: "160px",
    maxWidth: "300px",
    backgroundColor: "white"
  }
})(Dialog)

const styles = {
  root: {
    width: '100vw',
    height: '100vw',
    // position: 'relative',
    // top: '50px'
  },
  desktopRoot: {
    width: '70vw',
    height: '80vh',
    // position: 'relative',
    // top: '50px'
  },
  slide: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    height: '100vw'
  },
  desktopSlide: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    height: '80vh'
  },
  image: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    left: '0'
  },
  zoomed: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    left: '0'
  }
};

const imageReel = [feast, pbf, wings, skewers, pbn]

// const happyHourMenu = {
//     type: 'happy_hour',
//     name: 'Happy Hour',
//     image: null,
//     notes: ['Dine-in only', 'Monday to Friday', '2pm - 6pm'],
//     four: [
//       {
//         name: 'Shots',
//         description: [''],
//         img: ''
//       }
//     ],
//     five: [
//       {
//         name: 'Spring Roll (Lumpia Shanghai)',
//         description: ['Hand rolled filipino pork spring rolls'],
//         img: ''
//       },
//       {
//         name: 'Pork Skewers (3)',
//         description: ['House marinated pork skewers brushed with our homemade bbq sauce'],
//         img: ''
//       },
//       {
//         name: 'Chicken Skewers (3)',
//         description: ['House marinated chicken skewers brushed with our homemade bbq sauce'],
//         img: ''
//       },
//       {
//         name: 'Onion Rings',
//         description: ['Deep fried onion rings tossed in mango chipotle seasoning'],
//         img: ''
//       },
//       {
//         name: 'Amsterdam Blonde 16oz',
//         description: ['Lager, Crisp taste with a smooth finish'],
//         img: ''
//       },
//       {
//         name: 'Import Beer',
//         description: ['Heineken, Stella Artois, Corona'],
//         img: ''
//       },
//     ],
//     ten: [
//       {
//         name: 'Nachos',
//         description: ['Fresh tortilla chips topped with cheese, jalapenos, tomatoes, cilantro, green onion, and sour cream with salsa'],
//         img: ''
//       },
//       {
//         name: 'Chicken/Pork Taco',
//         description: ['Flour tortilla with tomatoes, green onion, cilantro, cheese, and spicy mayo'],
//         img: ''
//       }
//     ]
//   }

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageIndex: 0,
      showDialog: true
    };
  }
  componentDidMount() {
  }

  handleIndexChange = imageIndex => {
    this.setState({imageIndex});
  }

  render() {
    const { imageIndex, showDialog } = this.state;
    const isMobile = window.innerWidth < 768;
    return (
      <div className="landing">
        {/* <DineInDialog open={showDialog}>
          <div className="dialog-content">
            <div className="dialog-message">Dining now open!</div>
            <div className="close-dialog" onClick={() => this.setState({ showDialog: false })}>
              Close
            </div>
          </div>
        </DineInDialog> */}
        {/* <Modal/> */}
        <AutoPlaySwipeableViews
          style={isMobile ? styles.root : styles.desktopRoot}
          slideStyle={isMobile ? styles.slide : styles.desktopSlide}
          onChangeIndex={this.handleIndexChange}
          index={imageIndex}
          enableMouseEvents={true}
        >
          {imageReel.map((image, index) => {
            return (
              <img src={image} key={index} alt={index} style={Object.assign({}, isMobile && index < 5 ? styles.image : styles.zoomed)} />
            )
          })}
        </AutoPlaySwipeableViews>
        <ViewIndicator dots={imageReel.length} index={imageIndex} active="#414141" inactive="#ffffff" max={9} handleIndexChange={this.handleIndexChange} />
        <a href="https://www.doordash.com/business/357223/?utm_source=partner-link&utm_medium=website&utm_campaign=357223&utm_content=red-l" target="_blank" alt="Order Food Delivery with DoorDash" title="Order Food Delivery with DoorDash" style={{ textDecoration: 'none', marginBottom: '30px' }}>
          <div 
            className="doordash-link"
            style={{ 
              position: 'relative', 
              width: '289px', 
              height: '59px', 
              margin: '0px auto', 
              borderRadius: '30px',
              boxShadow: '0 10px 6px #cdcdcd',
              backgroundImage: "url('https://cdn.doordash.com/media/button/button_red_l.svg')", 
              color: 'transparent',
            }}>
              Order Food Delivery with DoorDash
            </div>
          </a>
        <h3 id="menu">
          <Link to='/menu' className="menu-link">
            Menu
          </Link>
          {/* <a target='_blank' href='https://limitless-bar-and-grill.square.site/' className="menu-link">
            Menu
          </a> */}
        </h3>
        <h3 id="new-items">
            <h2>Try our new items!</h2>
            <div id="item"><div>Sisig (Filipino Stir Fry)</div><div>$12.99</div></div>
            <div id="item"><div>Beef Steak Sandwich</div><div>$14.99</div></div>
            <div id="item"><div>Steak and Frites</div><div>$18.99</div></div>
            <div id="item"><div>Stir Fry Beef</div><div>$17.99</div></div>
        </h3>
        {/* <h3>Happy Hour Menu</h3>
        <div style={{ textAlign: 'center', padding: '0 16px', marginBottom: '32px' }}>
          {happyHourMenu.image ? 
          <div className="header-img">
            <img src={happyHourMenu.image} />
          </div> : null}
          <div className="heading">{happyHourMenu.name}</div>
          <div className="main-contents">
            {happyHourMenu.notes.map((note, index) => {
              return (
                <p key={index}>{note}</p>
              )
            })}
            <div className="main-item"><h2>$4</h2></div>
            {happyHourMenu.four.map((item, index) => {
              return (
                <div className="main-item" key={index}>
                  <p style={{ fontWeight: "600" }}>{item.name}</p>
                  <p className="description">{item.description.map(desc => <span>{desc}</span>)}</p>
                </div>
              )
            })}
            <div className="main-item"><h2>$5</h2></div>
            {happyHourMenu.five.map((item, index) => {
              return (
                <div className="main-item" key={index}>
                  <p style={{ fontWeight: "600" }}>{item.name}</p>
                  <p className="description">{item.description.map(desc => <span>{desc}</span>)}</p>
                </div>
              )
            })}
            <div className="main-item"><h2>$10</h2></div>
            {happyHourMenu.ten.map((item, index) => {
              return (
                <div className="main-item" key={index}>
                  <p style={{ fontWeight: "600" }}>{item.name}</p>
                  <p className="description">{item.description.map(desc => <span>{desc}</span>)}</p>
                </div>
              )
            })}
          </div>
        </div> */}
        {/* <Menu state={"word"} /> */}
        <h3 id="hours">Hours</h3>
        <Hours />
        <h3 id="contact">Contact</h3>
        <Contact />
      </div>
    )
  }
}
export default Landing
